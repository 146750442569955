import React from 'react';

import { wrapper, card } from './media-grid.module.scss';

import { IMedia } from '../../models/media.model';
import IVideoCaption from '../../models/video-caption.model';

import VideoModalCard from './video-modal-card';
import ImageModalCard from './image-modal-card';

interface IMediaGridProps {
    videos?: IVideoCaption[];
    images?: IMedia[];
}

const MediaGrid: React.FC<IMediaGridProps> = ({ videos, images }) => {
    return (
        <div className={wrapper}>
            {images?.map((image, index) => {
                return <ImageModalCard gallery={images} image={image} key={index} />;
            })}
            {videos?.map((video, index) => {
                return (
                    <VideoModalCard
                        key={`${video.title}_${index}`}
                        className={card}
                        video={video}
                    />
                );
            })}
        </div>
    );
};

export default MediaGrid;
