import React, { useRef } from 'react';

import { wrapper, videoWrapper, player, caption } from './video-modal-card.module.scss';

import { useModalContext } from '../../contexts/modal-context';
import IVideoCaption from '../../models/video-caption.model';

import Content from '../atoms/content';
import VideoPlayer from './video-player';

interface IVideoCard {
    className?: string;
    video: IVideoCaption;
}

const VideoModalCard: React.FC<IVideoCard> = ({ className = '', video }) => {
    const { showModal } = useModalContext();
    const buttonRef = useRef<HTMLButtonElement>(null);

    const returnFocus = () => {
        buttonRef.current?.focus();
    };

    const handleClick = () => {
        showModal({
            modalProps: { url: video.url, returnFocus: returnFocus },
            modalKey: 'video-modal',
        });
    };

    return (
        <button ref={buttonRef} className={`${wrapper} ${className}`} onClick={handleClick}>
            <div className={videoWrapper}>
                <VideoPlayer className={player} url={video.url} aspectRatio={9 / 16} />
            </div>
            <Content className={caption} numberOfStyle={2}>
                {video.title}
            </Content>
        </button>
    );
};

export default VideoModalCard;
