// extracted by mini-css-extract-plugin
export var aside = "company-module--aside--2f5b4";
export var category = "company-module--category--3c4ce";
export var container = "company-module--container--b65b3";
export var content = "company-module--content--d6a1f";
export var grid = "company-module--grid--c0e37";
export var heading = "company-module--heading--ec0cd";
export var image = "company-module--image--38b0b";
export var imageContainer = "company-module--image-container--4571d";
export var keywordsHeading = "company-module--keywords-heading--3c13e";
export var lead = "company-module--lead--77c74";
export var related = "company-module--related--51f85";
export var relatedHeading = "company-module--related-heading--e76c3";
export var resources = "company-module--resources--a493d";