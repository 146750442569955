import React from 'react';
import { Link } from 'gatsby';

import { featuredCard, link, imageContainer, image, heading } from './thumbnail-card.module.scss';
import { IMedia } from '../../models/media.model';
import useTranslations from '../../hooks/use-translations';

import RatioImage from '../atoms/ratio-image';
import Heading from '../atoms/heading';

export interface ICard {
    name: string;
    media: IMedia[];
    pathname: string;
}

interface IThumbnailCardProps {
    card: ICard;
    className?: string;
    isFirstCardFeatured?: boolean;
}

const ThumbnailCard: React.FC<IThumbnailCardProps> = ({
    className = '',
    card,
    isFirstCardFeatured = false,
}) => {
    const t = useTranslations('ThumbnailCard');
    const { name, media, pathname } = card || {};

    return (
        <article className={`${className} ${isFirstCardFeatured ? featuredCard : ''}`}>
            <Link to={pathname} aria-label={t.link({ name })} className={link}>
                <RatioImage media={media} className={imageContainer} imgClass={image} />
                <Heading className={heading} numberOfStyle={5} Tag={'h3'}>
                    {name}
                </Heading>
            </Link>
        </article>
    );
};

export default ThumbnailCard;
