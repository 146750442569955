import React from 'react';

import { list, item } from './keywords-listing.module.scss';
import { ILocationTag } from '../../models/location.model';
import { TButtonProps } from '../atoms/button';

import Keyword from '../atoms/keyword';

interface IKeywordsListingProps {
    className?: string;
    keywordClassName?: string;
    keywords: ILocationTag[];
    areKeywordsClickable?: boolean;
    keywordTheme?: TButtonProps['theme'];
    keywordOnClick?: (tag: string) => void;
}

const KeywordsListing: React.FC<IKeywordsListingProps> = ({
    className = '',
    keywordClassName = '',
    keywords,
    areKeywordsClickable = false,
    keywordTheme = 'gray-light',
    keywordOnClick = () => {},
}) => {
    return (
        <ul className={`${className} ${list}`}>
            {keywords.map((keyword) => (
                <li key={keyword.tagId} className={item}>
                    <Keyword
                        title={keyword.name}
                        className={keywordClassName}
                        isClickable={areKeywordsClickable}
                        theme={keywordTheme}
                        onClick={() => keywordOnClick(keyword.name)}
                    />
                </li>
            ))}
        </ul>
    );
};

export default KeywordsListing;
