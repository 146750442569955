import React from 'react';

import {
    columns3,
    columns4,
    featuredCard,
    card as cardClass,
} from './thumbnail-card-listing.module.scss';
import { ICard } from '../atoms/thumbnail-card';

import ThumbnailCard from '../atoms/thumbnail-card';

interface IThumbnailCardListingProps {
    className?: string;
    cards: ICard[];
    isFirstItemFeatured?: boolean;
    columnsAmount?: 3 | 4;
}

const ThumbnailCardListing: React.FC<IThumbnailCardListingProps> = ({
    className = '',
    cards,
    isFirstItemFeatured = false,
    columnsAmount = 4,
}) => {
    return (
        <section className={`${className} ${columnsAmount === 3 ? columns3 : columns4}`}>
            {cards.map((card, index) => (
                <ThumbnailCard
                    key={index}
                    card={card}
                    className={isFirstItemFeatured ? featuredCard : cardClass}
                    isFirstCardFeatured={isFirstItemFeatured}
                />
            ))}
        </section>
    );
};

export default ThumbnailCardListing;
