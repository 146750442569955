import React from 'react';

import { info, container, iconContainer, label as labelClass } from './contact-detail.module.scss';
import { IContact } from '../../models/contact.model';
import WebsiteIcon from '../../assets/images/svg/website.svg';
import EnvelopeIcon from '../../assets/images/svg/envelope.svg';
import TelIcon from '../../assets/images/svg/tel.svg';
import AddressIcon from '../../assets/images/svg/address.svg';

import Content from '../atoms/content';

type TContactKind = 'mail' | 'tel' | 'website' | 'address';

interface IContactDetailProps {
    className?: string;
    kind: TContactKind;
    data: IContact;
}

const ContactDetail: React.FC<IContactDetailProps> = ({ className = '', kind, data }) => {
    const label = data.label || data.value;
    const href = generateLinkHref(kind, data);
    const Icon = getIcon(kind);

    if (kind === 'address') {
        return (
            <Content className={`${info} ${container} ${className}`}>
                <span className={iconContainer}>
                    <Icon />
                </span>
                <span className={labelClass}>{label}</span>
            </Content>
        );
    }

    return (
        <a href={href} className={`${info} ${className}`} rel="noopener noreferrer" target="_blank">
            <Content Tag={'span'} className={container}>
                <span className={iconContainer}>
                    <Icon />
                </span>
                <span className={labelClass}>{label}</span>
            </Content>
        </a>
    );
};

const getIcon = (kind: TContactKind) => {
    const icons = {
        mail: EnvelopeIcon,
        tel: TelIcon,
        website: WebsiteIcon,
        address: AddressIcon,
    };

    return icons[kind];
};

const generateLinkHref = (kind: TContactKind, data: IContact) => {
    switch (kind) {
        case 'tel':
            return `tel:${data.value}`;
        case 'mail':
            return `mailto:${data.value}`;
        default:
            return data.value;
    }
};

export default ContactDetail;
