import React from 'react';
import { getImage } from 'gatsby-plugin-image';

import { wrapper, image as imageClass, imageRatio } from './image-modal-card.module.scss';

import { IMedia } from '../../models/media.model';
import { useModalContext } from '../../contexts/modal-context';

import BaseRatioImage from '../atoms/base-ratio-image';

interface IImageModalCardProps {
    gallery: IMedia[];
    image: IMedia;
}

const ImageModalCard: React.FC<IImageModalCardProps> = ({ gallery, image }) => {
    const { showModal } = useModalContext();

    const handleClick = () =>
        showModal({
            modalProps: { images: gallery, initialSlide: 0 },
            modalKey: 'gallery-modal',
        });

    return (
        <button className={wrapper} onClick={handleClick}>
            <BaseRatioImage
                image={(image.localFile && getImage(image.localFile)) || undefined}
                alt={image.alt}
                url={image.url}
                className={imageClass}
                ratioClass={imageRatio}
            />
        </button>
    );
};

export default ImageModalCard;
