import React from 'react';
import { graphql } from 'gatsby';

import {
    container,
    grid,
    heading,
    category,
    lead as leadClass,
    resources as resourcesClass,
    content as contentClass,
    imageContainer,
    image,
    relatedHeading,
    related,
    aside,
    keywordsHeading,
} from './company.module.scss';
import { IQueryAllResult } from '../models/query-all-result.model';
import { ICompany } from '../models/company.model';
import { getNodes } from '../utils/get-nodes';
import { getAllMediaWithRelation } from '../utils/get-media-with-relation';
import relations from '../config/relations';
import useTranslations from '../hooks/use-translations';

import MainLayout from '../layouts/main-layout';
import RatioImage from '../components/atoms/ratio-image';
import Heading from '../components/atoms/heading';
import Content from '../components/atoms/content';
import AccessibleContent from '../components/atoms/accessible-content';
import Markdown from '../components/hoc/markdown';
import CompanyContactDetails from '../components/molecules/company-contact-details';
import ThumbnailCardListing from '../components/molecules/thumbnail-card-listing';
import MediaGrid from '../components/molecules/media-grid';
import KeywordsListing from '../components/molecules/keywords-listing';

interface ICompanyQueryResults {
    allCompany: IQueryAllResult<ICompany>;
    currentCompany: ICompany;
}

interface ICompanyProps {
    data: ICompanyQueryResults;
}

const Company: React.FC<ICompanyProps> = ({ data }) => {
    const t = useTranslations('Company');
    const companies = getNodes(data.allCompany);
    const currentCompany = data.currentCompany;
    const relatedCompanies = companies.filter((company) =>
        currentCompany.relatedCompanyIds.includes(company.companyId)
    );
    const { media, name, categories, lead, resources, content, contacts, videos, tags } =
        currentCompany;
    const firstCategory = categories[0];
    const additionalImages = getAllMediaWithRelation(media, relations.gallery);

    return (
        <MainLayout>
            <div className={grid}>
                <RatioImage media={media} className={imageContainer} imgClass={image} />
                <div className={container}>
                    <div>
                        <Heading numberOfStyle={3} Tag={'h1'} className={heading}>
                            {name}
                        </Heading>
                        {firstCategory && (
                            <AccessibleContent
                                className={category}
                                screenReadersContent={t.category({ name: firstCategory.name })}
                                numberOfStyle={1}
                            >
                                {firstCategory.name}
                            </AccessibleContent>
                        )}
                        {lead && <Content className={leadClass}>{lead}</Content>}
                        {(resources || content) && (
                            <Heading numberOfStyle={5} Tag={'h2'} className={heading}>
                                {t.contentHeading}
                            </Heading>
                        )}
                        {resources && <Markdown className={resourcesClass}>{resources}</Markdown>}
                        {content && (
                            <Markdown components={{ p: 'li' }} className={contentClass}>
                                {content}
                            </Markdown>
                        )}
                        {(videos.length > 0 || additionalImages.length > 0) && (
                            <>
                                <Heading numberOfStyle={5} Tag={'h2'} className={heading}>
                                    {t.videosHeading}
                                </Heading>
                                <MediaGrid videos={videos} images={additionalImages} />
                            </>
                        )}
                    </div>
                    <div className={aside}>
                        {tags.length > 0 && (
                            <div>
                                <Heading Tag={'h3'} numberOfStyle={5} className={keywordsHeading}>
                                    {t.keywordsHeading}
                                </Heading>
                                <KeywordsListing keywords={tags} />
                            </div>
                        )}
                        <CompanyContactDetails contacts={contacts} />
                    </div>
                </div>
                {relatedCompanies.length > 0 && (
                    <div className={related}>
                        <Heading Tag={'h2'} numberOfStyle={3} className={relatedHeading}>
                            {t.relatedHeading}
                        </Heading>
                        <ThumbnailCardListing cards={relatedCompanies} />
                    </div>
                )}
            </div>
        </MainLayout>
    );
};

export const query = graphql`
    query ($companyId: Int) {
        allCompany {
            edges {
                node {
                    ...companyFields
                }
            }
        }

        currentCompany: company(companyId: { eq: $companyId }) {
            ...companyFields
        }
    }
`;

export default Company;
