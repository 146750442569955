import React from 'react';

import { button, padding, nonClickable } from './keyword.module.scss';

import Button, { TButtonProps } from './button';
import Content from './content';

interface IKeywordProps {
    className?: string;
    isClickable?: boolean;
    title: string;
    theme?: TButtonProps['theme'];
    onClick?: () => void;
}

const Keyword: React.FC<IKeywordProps> = ({
    className = '',
    title,
    isClickable = false,
    theme = 'gray-light',
    onClick = () => {},
}) => {
    if (!title) return null;

    const buttonProps = getButtonProps(isClickable, className, theme, onClick);

    return (
        <Button {...buttonProps}>
            <Content Tag={isClickable ? 'span' : 'p'}>{title}</Content>
        </Button>
    );
};

interface IButtonProps {
    as: Extract<TButtonProps['as'], 'button' | 'element'>;
    theme: TButtonProps['theme'];
    className: TButtonProps['className'];
    onClick?: () => void;
}

const getButtonProps = (
    isClickable: boolean,
    className: string,
    theme: TButtonProps['theme'],
    onClick: () => void
): IButtonProps => {
    const props: IButtonProps = {
        as: isClickable ? 'button' : 'element',
        theme: theme,
        className: `${className} ${padding} ${button} ${isClickable ? '' : nonClickable}`,
    };

    if (isClickable) {
        props.onClick = () => onClick();
    }

    return props;
};

export default Keyword;

// TODO : Dodać jakiś tekst do tagów w stylu "Pokaż lokacje z tagu XYZ" jak już ustalimy jak konkretnie to wszystko ma się zachowywać.
