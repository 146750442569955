import React from 'react';

import { container } from './company-contact-details.module.scss';
import { IContactData } from '../../models/contact.model';
import useTranslations from '../../hooks/use-translations';

import ContactDetails from './contact-details';

interface ICompanyContactDetailsProps {
    className?: string;
    contacts: IContactData[];
}

const CompanyContactDetails: React.FC<ICompanyContactDetailsProps> = ({
    className = '',
    contacts,
}) => {
    const t = useTranslations('CompanyContactDetails');

    const generalContacts = contacts.filter((contact) => contact.businessMeaning === 'contact');
    const personContacts = contacts.filter((contact) => contact.businessMeaning === 'person');

    return (
        <div className={`${className} ${container}`}>
            {generalContacts.length > 0 && <ContactDetails contacts={generalContacts} />}
            {personContacts.length > 0 && (
                <ContactDetails contacts={personContacts} heading={t.personHeading} />
            )}
        </div>
    );
};

export default CompanyContactDetails;
