import React from 'react';

import { address, heading as headingClass } from './contact-details.module.scss';
import { IContactData } from '../../models/contact.model';
import useTranslations from '../../hooks/use-translations';

import Heading from '../atoms/heading';
import Content from '../atoms/content';
import AccessibleContent from '../atoms/accessible-content';
import ContactDetail from '../atoms/contact-detail';

interface IContactDetailsProps {
    className?: string;
    contacts: IContactData | IContactData[];
    heading?: string;
    showHeading?: boolean;
    showLabel?: boolean;
    showEmails?: boolean;
    showPhoneNumbers?: boolean;
    showWebsites?: boolean;
    showAddress?: boolean;
}

const ContactDetails: React.FC<IContactDetailsProps> = ({
    className = '',
    contacts,
    heading = '',
    showHeading = true,
    showLabel = true,
    showEmails = true,
    showPhoneNumbers = true,
    showWebsites = true,
    showAddress = true,
}) => {
    const t = useTranslations('ContactDetails');

    if (!Array.isArray(contacts)) {
        contacts = [contacts];
    }

    return (
        <div className={className}>
            {showHeading && (
                <Heading Tag={'h3'} numberOfStyle={5} className={headingClass}>
                    {heading ? heading : t.heading}
                </Heading>
            )}

            {contacts.map((contact, index) => {
                const { label, emails, phoneNumbers, websites, locations } = contact;

                return (
                    <address key={index} className={address}>
                        {label && showLabel && <Content isRegular={false}>{label}</Content>}

                        {emails.length > 0 && showEmails && (
                            <>
                                <AccessibleContent screenReadersContent={t.emails} />
                                {emails.map((email, index) => (
                                    <ContactDetail
                                        key={index}
                                        kind={'mail'}
                                        data={{ label: email, value: email }}
                                    />
                                ))}
                            </>
                        )}

                        {phoneNumbers.length > 0 && showPhoneNumbers && (
                            <>
                                <AccessibleContent screenReadersContent={t.phones} />
                                {phoneNumbers.map((phone, index) => (
                                    <ContactDetail key={index} kind={'tel'} data={phone} />
                                ))}
                            </>
                        )}

                        {websites.length > 0 && showWebsites && (
                            <>
                                <AccessibleContent screenReadersContent={t.websites} />
                                {websites.map((website, index) => (
                                    <ContactDetail key={index} kind={'website'} data={website} />
                                ))}
                            </>
                        )}

                        {locations && showAddress && (
                            <>
                                <AccessibleContent screenReadersContent={t.addresses} />
                                {locations.map((location, index) => (
                                    <ContactDetail
                                        key={index}
                                        kind={'address'}
                                        data={{ label: location, value: location }}
                                    />
                                ))}
                            </>
                        )}
                    </address>
                );
            })}
        </div>
    );
};

export default ContactDetails;
